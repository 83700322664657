import styled from 'styled-components';
import tokens from '@veneer/tokens';

export const Header = styled.div`
  line-height: 28px;
  padding-bottom: 5px;
  margin-bottom: 13px;
  border-bottom: 1px solid ${tokens.color.gray2};
  border-spacing: 10px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > a {
    > img {
      height: 40px;
      width: auto;
      padding-right: 10px;
      padding-bottom: 10px;
    }
  }
`;

export const Description = styled.div`
  font-size: ${tokens.typography.size1};
  padding-bottom: 15px;
`;
