import React from 'react';
import PropTypes from 'prop-types';
import { IconCheckCircle, ProgressIndicator } from '@veneer/core';
import { useTheme } from '@veneer/theme';
import { useI18n } from '@jarvis/react-portal-addons';
import {
  Card, Container, Status, StatusText, Title,
} from './styles';

const Subscription = ({ subscriptionStatus }) => {
  const { t } = useI18n();
  const theme = useTheme();

  if (Object.keys(subscriptionStatus).length > 0) {
    return (
      <Container data-testid="subscription-container">
        <Title>{t('subscriptionStatus.header')}</Title>
        <Status>
          <IconCheckCircle
            size={24}
            filled
            customStyle={{
              color: theme.palette.positive.default,
            }}
          />
          <StatusText>{t('subscriptionStatus.status.enrolled')}</StatusText>
        </Status>
      </Container>
    );
  }
  return <ProgressIndicator behavior="indeterminate" delay={800} />;
};

Subscription.defaultProps = {
  subscriptionStatus: null,
};

Subscription.propTypes = {
  subscriptionStatus: PropTypes.objectOf(PropTypes.any),
};

export const SubscriptionCard = ({ subscriptionStatus }) => {
  const { t } = useI18n();
  const theme = useTheme();

  return (
    <Card
      content={
        Object.keys(subscriptionStatus).length > 0 ? (
          <>
            <Title className="subtitle-regular">
              {t('subscriptionStatus.header')}
            </Title>
            <div>
              <Status>
                <IconCheckCircle
                  size={24}
                  customStyle={{
                    color: theme.palette.positive.default,
                  }}
                  filled
                />
                <StatusText>
                  {t('subscriptionStatus.status.enrolled')}
                </StatusText>
              </Status>
            </div>
          </>
        ) : (
          <ProgressIndicator behavior="indeterminate" delay={800} />
        )
      }
    />
  );
};

SubscriptionCard.defaultProps = {
  subscriptionStatus: null,
};

SubscriptionCard.propTypes = {
  subscriptionStatus: PropTypes.objectOf(PropTypes.any),
};

export default Subscription;
