export default function getBreadcrumbsItems(t) {
  const navigation = window?.Shell?.v1.navigation;
  const breadCrumbsItems = [
    {
      text: t('breadcrumbs.home'),
      onClick: e => {
        e.preventDefault();
        navigation.push('/');
      },
      url: '/',
    },
    {
      text: t('breadcrumbs.solutions'),
      onClick: e => {
        e.preventDefault();
        navigation.push('/solutions');
      },
      url: '/solutions',
    },
    {
      text: 'Hp Smart Pro',
    },
  ];
  return breadCrumbsItems;
}
