import environmentConfigMock from '../mock/mock-environment-config';

const mockEnvironmentConfig = false;

export function shouldShowStoresShortcuts(environmentConfig) {
  const isJWeb = environmentConfig && environmentConfig.isNative;
  const appStoresEnabled = environmentConfig
    && environmentConfig.flags
    && environmentConfig.flags.smartProAppStores;
  const showAppStores = !isJWeb && appStoresEnabled;
  const showShortcuts = environmentConfig
    && environmentConfig.flags
    && environmentConfig.flags.smartProShortcuts;
  return { showAppStores, showShortcuts };
}

export function getReleaseFeatureFlag(environmentConfig) {
  const releaseFeatureFlag = (environmentConfig
      && environmentConfig.flags
      && environmentConfig.flags.releaseVersionHpSmartPro)
    || 'published';
  return releaseFeatureFlag;
}

export function getEnvironmentConfig(environmentConfig = null) {
  return mockEnvironmentConfig || environmentConfig === null
    ? environmentConfigMock
    : environmentConfig;
}
