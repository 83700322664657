import styled from 'styled-components';
import tokens from '@veneer/tokens';

export const StyledMobileCarouselItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  min-height: 243px;
  width: 288px;
`;

export const Image = styled.div`
  align-items: center;
  background-color: #d2ecff;

  display: flex;
  height: 151px;
  justify-content: center;
  width: 288px;

  img {
    height: 80px;
  }
`;

export const AnimationContainer = styled.div`
  border-radius: ${tokens.layout.cornerRadius4} ${tokens.layout.cornerRadius4}
    ${tokens.layout.cornerRadius0} ${tokens.layout.cornerRadius0};
  height: 151px;
  overflow: hidden;
  width: 288px;
`;

export const Content = styled.div`
  background-color: ${tokens.color.white};
  border-radius: ${tokens.layout.cornerRadius0} ${tokens.layout.cornerRadius0}
    ${tokens.layout.cornerRadius4} ${tokens.layout.cornerRadius4};
  flex-grow: 1;
  padding: 14px 18px 18px 18px;
  width: 252px;
  margin-bottom: 18px;
`;

export const Title = styled.div`
  color: ${tokens.color.gray12};
`;

export const Description = styled.div`
  color: ${tokens.color.gray9};
`;
