import styled from 'styled-components';
import { Card as VeneerCard } from '@veneer/core';
import tokens from '@veneer/tokens';

export const Container = styled.div`
  font-size: ${tokens.typography.size4};
`;

export const Title = styled.div`
  border-bottom: 1px solid ${tokens.color.gray2};
  padding-bottom: 16px;
`;

export const Status = styled.div`
  align-items: center;
  display: flex;
  padding-top: 24px;
  padding-bottom: 15px;
`;

export const StatusText = styled.span`
  padding-left: 5px;
`;

export const Card = styled(VeneerCard)`
  box-shadow: 0 12px 40px #6a6a6a1a;
  font-size: ${tokens.typography.size4};
  padding: 26px 32px;
  width: 870px;
  @media (max-width: ${props => props.theme.screens.dxlg}) {
    ${props => props.theme.host.isDesktopApp && 'width: 572px;'};
  }
  @media (max-width: ${props => props.theme.screens.xlg}) {
    width: 572px;
  }
  @media (max-width: ${props => props.theme.screens.dsm}) {
    ${props => props.theme.host.isDesktopApp && 'width: 475px;'}
  }
  @media (max-width: ${props => props.theme.screens.sm}) {
    width: 475px;
  }
  @media (max-width: ${props => props.theme.screens.dxsm}) {
    ${props => props.theme.host.isDesktopApp && 'width: 277px;'}
  }
  @media (max-width: ${props => props.theme.screens.xsm}) {
    width: 277px;
  }
`;
