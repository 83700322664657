import styled from 'styled-components';
import tokens from '@veneer/tokens';

export const Asterisk = styled.p`
  font-family: ${tokens.typography.family1};
  font-size: ${tokens.typography.size1};
  text-align: ${props => (props.type === 'mobile' ? 'left' : 'center')};
  margin-left: ${props => (props.type === 'mobile' ? '39px' : 0)};
  margin-bottom: 0;
  margin-top: 0;
  width: 100%;

  @media (max-width: ${props => props.theme.screens.sm}) {
    max-width: ${props => (props.type === 'mobile' ? '516px' : '100%')};
  }

  @media (max-width: ${props => props.theme.screens.xsm}) {
    max-width: ${props => (props.type === 'mobile' ? '294px' : '100%')};
  }
`;

export default Asterisk;
