import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';
import { useI18n, useRootContext } from '@jarvis/react-portal-addons';
import { Content, Description, Header } from './styles';
import { Card, Title } from '../Card/styles';
import { redundantLocales } from '../../assets/locale';
import {
  GoogleAppStoreBadge,
  MacAppStoreBadge,
  MicrosoftAppStoreBadge,
  iOSAppStoreBadge,
} from '../../assets/locale/appStoreBadges';

const AppStoreBadge = ({ image, url }) => (
  <a
    href={url} target="_blank"
    rel="noopener noreferrer"
  >
    <img src={image.url} alt={image.alt} />
  </a>
);

AppStoreBadge.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  url: PropTypes.string.isRequired,
};

const getLocaleRedundant = locale => propOr(locale, locale, redundantLocales);

const AppStores = () => {
  const { t } = useI18n();
  const {
    localization: { locale = null },
  } = useRootContext();

  const localeTreated = locale?.replace('-', '_');

  return (
    <Card
      content={(
        <>
          <Header>
            <Title>{t('appStores.cardHeader')}</Title>
          </Header>
          <Description>{t('appStores.cardDescription')}</Description>
          <Content>
            <AppStoreBadge
              image={{
                url: iOSAppStoreBadge[getLocaleRedundant(localeTreated)]?.toString(),
                alt: 'Download the Smart App on the App Store',
              }}
              url="https://apps.apple.com/app/hp-smart/id469284907"
            />
            <AppStoreBadge
              image={{
                url: GoogleAppStoreBadge[
                  getLocaleRedundant(localeTreated)
                ]?.toString(),
                alt: 'Download the Smart App on Google Play',
              }}
              url="https://play.google.com/store/apps/details?id=com.hp.printercontrol"
            />
            <AppStoreBadge
              image={{
                url: MicrosoftAppStoreBadge[
                  getLocaleRedundant(localeTreated)
                ]?.toString(),
                alt: 'Download the Smart App from Microsoft',
              }}
              url="https://www.microsoft.com/p/hp-smart/9wzdncrfhwlh?rtc=1"
            />
            <AppStoreBadge
              image={{
                url: MacAppStoreBadge[getLocaleRedundant(localeTreated)]?.toString(),
                alt: 'Download the Smart App on the Mac App Store',
              }}
              url="https://apps.apple.com/app/hp-smart-for-desktop/id1474276998"
            />
          </Content>
        </>
      )}
    />
  );
};

export default AppStores;
